<template>
  <a-form
    class="iss-form"
    ref="formRef"
    :model="form"
    :colon="false"
    :label-col="{ span: labelCol }"
    :wrapper-col="{ span: wrapperCol }"
    label-align="left"
  >
    <a-row :gutter="24" type="flex">
      <a-col v-for="item in items" :key="item.key" v-bind="span">
        <template v-if="item.type === 'radio'">
          <a-form-item :name="item.key" :label="item.label">
            <a-radio-group
              v-model:value="form[item.key]"
              :options="item.dataset"
            />
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'range'">
          <a-form-item :label="item.label" class="range-wrapper">
            <a-form-item :name="item.start" class="item">
              <a-input-number
                v-model:value="form[item.start]"
                placeholder="请输入开始值"
              />
            </a-form-item>
            <span class="separator"> ~ </span>
            <a-form-item :name="item.end" class="item">
              <a-input-number
                v-model:value="form[item.end]"
                placeholder="请输入结束值"
              />
            </a-form-item>
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'treeSelect'">
          <a-form-item :name="item.key" :label="item.label">
            <a-tree-select
              v-model:value="form[item.key]"
              :placeholder="`请选择${item.label}`"
              allow-clear
              :tree-data="dataset[`${item.key}List`]"
              v-bind="item.options"
            />
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'select'">
          <a-form-item :name="item.key" :label="item.label">
            <a-select
              :show-search="item.showSearch"
              v-model:value="form[item.key]"
              :options="dataset[`${item.key}List`]"
              :placeholder="`请选择${item.label}`"
              :mode="item.mode || null"
              allow-clear
              :getPopupContainer="() => $refs.formRef.$el"
              :filter-option="
                (inputValue, option) =>
                  handleFilterOption(inputValue, option, item)
              "
              @change="(value, option) => handleChange(option, item)"
            />
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'checkbox'">
          <a-form-item>
            <a-checkbox-group
              v-model:value="form[item.key]"
              :options="dataset[`${item.key}List`]"
            />
          </a-form-item>
        </template>
        <template v-else>
          <a-form-item :name="item.key" :label="item.label">
            <a-input
              v-model:value="form[item.key]"
              autocomplete="off"
              :placeholder="`请输入${item.label}`"
            />
          </a-form-item>
        </template>
      </a-col>
      <a-col :flex="1">
        <a-form-item class="ta-right" :wrapper-col="{ span: 24 }">
          <a-space :size="12">
            <a-button type="primary" @click="handleClickSearch">
              查询
            </a-button>
            <a-button @click="resetFields">重置</a-button>
          </a-space>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import {
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  TreeSelect,
  Checkbox,
} from 'ant-design-vue';

export default {
  name: 'SearchForm',
  components: {
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ARadioGroup: Radio.Group,
    ARow: Row,
    ASpace: Space,
    AInputNumber: InputNumber,
    ATreeSelect: TreeSelect,
    ASelect: Select,
    ACheckboxGroup: Checkbox.Group,
  },
  props: {
    items: Array,
    layout: Object,
    defaultValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: { ...this.defaultValue },
      dataset: {},
      labelCol: null,
      wrapperCol: null,
      span: this.layout || this.getSpan(),
    };
  },
  created() {
    // let isSet = false;
    this.items.forEach(item => {
      if (item.url) {
        this.$http
          .get(item.url, { params: { ...item.urlParams }, code: item.code })
          .then(data => {
            this.dataset[`${item.key}List`] =
              item.valueKey || item.labelKey
                ? data.map(i => ({
                    value: i[item.valueKey || 'value'],
                    label: i[item.labelKey || 'label'],
                    children: i[item.childrenKey || 'children'],
                  }))
                : data;
          });
      } else if (item.dataset) {
        this.dataset[`${item.key}List`] = item.dataset;
      }
      // if (this.items.length > 3 && item.label?.length > 2 && !isSet) {
      //   this.labelCol = 6;
      //   this.wrapperCol = 18;
      //   isSet = true;
      // }
    });
  },
  methods: {
    handleClickSearch() {
      let temp = {};
      for (const key in this.form) {
        const item$1 = this.items.find(item => item.key === key);
        if (
          item$1 &&
          !['range', 'radio', 'checkbox', 'select', 'treeSelect'].includes(
            item$1?.type
          )
        )
          this.form[key] = this.form[key]?.trim();
        [undefined, ''].includes(this.form[key]) ||
          (temp[key] = this.form[key]);
      }
      this.$emit('fnSearch', temp);
    },
    resetFields() {
      this.items.forEach(item => {
        if (item.type === 'select' && item.mode === 'multiple') {
          this.form[item.key] = '';
        }
      });
      this.$refs.formRef.resetFields();
      this.handleClickSearch();
    },
    getSpan() {
      switch (this.items.length) {
        case 1:
          return { sm: 24, md: 12 };
        case 2:
          return { sm: 24, md: 12, lg: 8 };
        default:
          return { sm: 24, md: 12, lg: 8, xl: 6 };
      }
    },
    handleChange(option, item) {
      item.fnChange && item.fnChange(option);
      this.items.forEach(i => {
        if (i.url && item.linkedKey === i.key) {
          console.log('i.key', i.key);
          this.form[item.linkedKey] = null;
          this.$http.get(i.url, { params: i.urlParams, code: i.code }).then(
            data =>
              (this.dataset[`${i.key}List`] =
                i.valueKey || i.labelKey
                  ? data.map(e => ({
                      value: e[i.valueKey || 'value'],
                      label: e[i.labelKey || 'label'],
                      children: e[i.childrenKey || 'children'],
                    }))
                  : data)
          );
        } else if (item.linkedKey === i.key) {
          this.dataset[`${item.linkedKey}List`] =
            i.valueKey || i.labelKey
              ? option.children?.map(e => ({
                  value: e[i.valueKey || 'value'],
                  label: e[i.labelKey || 'label'],
                  children: e[i.childrenKey || 'children'],
                }))
              : option.children;
        }
      });
    },
    handleFilterOption(inputValue, option, item) {
      let filterKey = 'value';
      item.filterOption === 'label' && (filterKey = 'label');
      return option[filterKey].indexOf(inputValue) >= 0;
    },
  },
};
</script>

<style lang="less" scoped>
.iss-form {
  .range-wrapper {
    :deep(.ant-form-item-control-input-content) {
      display: flex;
      align-items: center;
      .item {
        margin-bottom: 0;
        flex: 1;
        .ant-input-number {
          width: 100%;
        }
      }
      .separator {
        margin: 0 12px;
      }
    }
  }
  .ta-right {
    margin-bottom: 0px;
  }
  :deep(.ant-form-item) {
    margin-bottom: 16px;
  }
}
</style>
