'use strict';

import http from '@/utils/http';

export default {
  // 新闻分页
  newsUrl: '/isite-news/page',
  // 新闻查询
  getNews(code, id) {
    return http.get(`/isite-news/${id}`, { code });
  },

  // 新增新闻
  addNews(code, data) {
    return http.post('/isite-news', data, { code });
  },
  // 编辑新闻
  updateNews(code, data) {
    return http.put('/isite-news', data, { code });
  },
  // 删除新闻
  deleteNews(code, params) {
    return http.delete('/isite-news', { params, code });
  },
  // 审核通过接口
  passNews(code, data) {
    return http.post('/isite-news/auditIsiteNews', data, { code });
  },
  newsPublish(code, data) {
    return http.post('/isite-news/publish', data, {
      code,
    });
  },
};
