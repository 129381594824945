<template>
  <!-- todo 标题是新闻标题  -->
  <a-modal
    :visible="visible"
    :title="form.newsInfo.newsTitle"
    :mask-closable="false"
    :width="900"
    class="model"
    :footer="null"
    @cancel="handleCancel"
    style="top: 20px"
  >
    <div v-html="form.newsInfo?.newsContent"></div>
  </a-modal>
</template>
<script>
// toRaw,
import { reactive, toRefs, watch } from 'vue';
// import { Form,  } from 'ant-design-vue';
// import moment from 'moment';
// import formApi from '@/api/form';

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const form = reactive({
      id: '',
      title: '',
      newsInfo: '',
    });
    const state = reactive({});

    watch(
      () => props.initValue,
      initValue => {
        form.newsInfo = initValue;
        console.log(initValue);
      }
    );

    return {
      ...toRefs(state),
      form,
      handleCancel: () => {
        context.emit('update:visible', false);
      },
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
