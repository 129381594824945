<template>
  <div class="iss-main-grid" ref="mainRef">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <!-- todo 需要补齐  -->
        <template #auditStatus="{ record }">
          <div v-if="record.auditStatus === 0">未审核</div>
          <div v-else-if="record.auditStatus === 1">审核通过</div>
          <div v-else-if="record.auditStatus === 2">审核不通过</div>
        </template>

        <template #publishStatus="{ record }">
          <div v-if="record.publishStatus === 0">未发布</div>
          <div v-else-if="record.publishStatus === 1">已发布</div>
        </template>

        <template #newsContent="{ record }">
          <div class="iss-user">
            <a-tooltip title="查看内容" placement="top">
              <a-button
                type="primary"
                size="middle"
                shape="circle"
                ghost
                @click="handleClickNewsContent(record)"
              >
                <template #icon><EyeOutlined /></template>
              </a-button>
            </a-tooltip>
            <span style="padding-left: 5px">{{ record.number }}</span>
          </div>
        </template>

        <template #language="{ record }">
          <div v-if="record.language === 'Chinese'">中文</div>
          <div v-if="record.language === 'English'">英文</div>
        </template>

        <template #bannerUrl="{ record }">
          <template v-if="record.bannerUrl">
            <a @click="handleClickByCopyLink(record.bannerUrl)">复制链接</a>
            <a-divider type="vertical" />
            <a :href="getVisitLink(record.bannerUrl)" target="_blank"
              >直接打开</a
            >
          </template>
        </template>
      </grid>
    </div>
  </div>
  <iss-view
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
    :container="mainRef"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tag, message, Tooltip, Divider } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import newsApi from '@/api/news.js';
import issView from './components/view';
import { EyeOutlined } from '@ant-design/icons-vue';
import { copyText } from '@/utils';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    ATooltip: Tooltip,
    ADivider: Divider,
    issView,
    EyeOutlined,
  },
  setup() {
    const gridRef = ref();
    const mainRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });
    const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });

    const handleClickNewsContent = record => {
      console.log('record', record);
      state.visible = true;
      state.activeItem = record;
    };

    const getVisitLink = url => `${url}`;

    return {
      handleClickNewsContent,
      mainRef,
      gridRef,
      store,
      ...toRefs(state),
      getVisitLink,

      items: [
        { key: 'newsTitle', label: '新闻标题' },

        {
          key: 'language',
          label: '语言',
          type: 'select',
          dataset: [
            { label: '中文', value: 'Chinese' },
            { label: '英文', value: 'English' },
          ],
        },
        {
          key: 'newTypeName',
          label: '新闻类型',
          type: 'select',
          valueKey: 'id',
          labelKey: 'categoryName',
          url: `/category/getCategorySon?moduleCode=news`,
        },

        {
          key: 'publishStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已发布' },
            { value: '0', label: '未发布' },
          ],
        },
        {
          key: 'auditStatus',
          label: '审核状态',
          type: 'select',
          dataset: [
            { value: '0', label: '未审核' },
            { value: '1', label: '审核通过' },
            { value: '2', label: '审核不通过' },
          ],
        },

        // {
        //   key: 'releaseTime',
        //   label: '发布时间',
        //   type: 'select',
        //   dataset: [
        //     { value: '0', label: '未审核' },
        //     { value: '1', label: '审核通过' },
        //     { value: '2', label: '审核不通过' },
        //   ],
        // },
      ],
      columns: [
        {
          dataIndex: 'newTypeName',
          title: '新闻类型',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'newsTitle',
          title: '新闻标题',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'releaseTime',
          title: '发布时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'bannerUrl',
          title: '新闻banner图',
          width: 120,
          slots: { customRender: 'bannerUrl' },
          ellipsis: true,
        },
        {
          dataIndex: 'language',
          title: '语言',
          width: 120,
          slots: { customRender: 'language' },
          ellipsis: true,
        },
        //
        {
          dataIndex: 'newsContent',
          title: '内容',
          width: 120,
          slots: { customRender: 'newsContent' },
          ellipsis: true,
        },
        {
          dataIndex: 'auditStatus',
          title: '审核状态',
          width: 120,
          slots: { customRender: 'auditStatus' },
          ellipsis: true,
        },
        // 点击打开议程 详情页
        {
          dataIndex: 'publishStatus',
          title: '发布状态',
          width: 120,
          slots: { customRender: 'publishStatus' },
          ellipsis: true,
        },
        {
          dataIndex: 'sort',
          title: '排序',
          width: 120,
          slots: { customRender: 'sort' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 120,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            router.push({
              name: 'newsDetail',
              params: { id: 'add', status: 'add' },
            });
          },
        },

        {
          type: 'isPublish',
          label: '发布',
          icon: 'ExportOutlined',
          permission: 'agenda:publish',
          fnClick: () => {
            newsApi.newsPublish('', '').then(() => {
              message.success('发布成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],

      options: [
        // todo 通过 拒绝接口 未联调

        {
          type: 'update',
          label: '编辑',
          permission: 'agenda:update',
          fnClick: record => {
            store.commit('common/setNewsContent', record);
            // router 跳转到查看议程
            let id = record.id;
            router.push({
              name: 'newsDetail',
              params: { id: id, status: 'edit' },
            });
          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            newsApi.deleteNews('resources:delete', { id: id }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
        {
          type: 'pass',
          icon: 'CheckCircleOutlined',
          permission: 'classify:pass',
          label: '通过',
          show: record => {
            if (record.auditStatus === 0) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            let parmas = {
              id: record.id,
              auditStatus: 1,
            };
            newsApi.passNews('', parmas).then(() => {
              message.success('审核通过');
              gridRef.value.refreshGrid();
            });
          },
        },
        {
          type: 'refuse',
          icon: 'StopOutlined',
          permission: 'classify:refuse',
          label: '拒绝',
          show: record => {
            if (record.auditStatus === 0) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            let parmas = {
              id: record.id,
              auditStatus: 2,
            };
            newsApi.passNews('', parmas).then(() => {
              gridRef.value.refreshGrid();
              message.success('操作成功');
            });
          },
        },
      ],
      url: newsApi.newsUrl,
      gridHeight: document.body.clientHeight - 386,

      handleClickByCopyLink: url => {
        copyText(getVisitLink(url));
        message.success('复制链接成功！');
      },

      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');

        newsApi[type](`news:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
          setTimeout(() => {}, 1500);
        });

        // 保存失败后 恢复保存按钮
        setTimeout(() => {}, 1500);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  :deep(.ant-modal-mask) {
    z-index: 100;
  }
  :deep(.ant-modal-wrap) {
    z-index: 100;
  }
  :deep(.ant-modal-body) {
    max-height: calc(100vh - 188px);
    overflow: auto;
  }
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
